@import "variables";

.button {
  font-family: $button-font-family !important;
  font-size: $lg-button-font-multiplier * $button-font-size !important;
  color: $button-font-color !important;
  font-weight: $button-font-weight !important;
  background-image: none;
  padding: 2px 8px !important;
  background-color: $button-background-color !important;
  border-radius: 3px !important;

  &[disabled] {
    opacity: 0.5;
  }

  @include media-breakpoint-up(xl) {
    font-size: $button-font-size !important;
  }
}
.button-alternative {
  background-color: $dark-gray !important;
}
.btn-atualiza-atividade{
  background-color: $dark-orange !important;
  font-size: 12px !important;
}
.btn-atualiza-atividade-concluido{
  background-color: transparent !important;
  border: none !important;
  color: black !important;
  box-shadow: none !important;
  font-size: 12px !important;
  cursor: default !important;
}
.button-atuar {
  border: 1px solid $brand-primary-2 !important;
  color: $brand-primary-2 !important;
  padding: 0 8px !important;
  height: 18px;
  font-size: 10.5px !important;
  background-image: none !important;

  @include media-breakpoint-up(xl) {
    font-size: 12px !important;
    height: 22px;
  }
}
.button-atuar-remote {
  border: 1px solid $brand-secondary-1 !important;
  color: $brand-secondary-1 !important;
}
.button-alterar {
  border: 1px solid $brand-primary-2 !important;
  color: $brand-primary-2 !important;
  padding: 0 6px !important;
  font-size: 10.5px !important;
  background-image: none !important;

  @include media-breakpoint-up(xl) {
    font-size: 12px !important;
  }
}
.botao-participante{
  font-size: 14px !important;
}
.button-action {
  background-image: $activity-action-button-color !important;
}
.button-disabled {
  background: $activity-disabled-button-color none !important;
}
.button-more {
  background: $brand-secondary-1 none !important;
}
.transparent-button {
  background-color: transparent !important;
  font-size: $lg-button-font-multiplier * 15 !important;

  border: none !important;

  @include media-breakpoint-up(xl) {
    font-size: 14px !important;
  }
}

@import "variables";

%paginacao-text {
  font-size: $lg-default-font-multiplier * $paginacao-text-font-size;
  color: $paginacao-text-font-color;
  font-weight: $paginacao-text-font-weight;

  @include media-breakpoint-up(xl) {    
    font-size: $paginacao-text-font-size;
  }
}
%paginacao-link {
  @extend %paginacao-text;
  text-align: center;
  display: flex;
  height: $lg-default-font-multiplier * $paginacao-button-size;
  width: $lg-default-font-multiplier * $paginacao-button-size;
  margin-right: 0.5em;
  align-items: center;
  justify-content: center;
  background-color: $paginacao-background-color;
  border-radius: 100px;
  cursor: pointer;

  @include media-breakpoint-up(xl) {    
    height: $paginacao-button-size;
    width: $paginacao-button-size;
  }
}

.paginacao-link {
  @extend %paginacao-link;
}
.paginacao-text {
  @extend %paginacao-text;
}
.paginacao-link-selected {
  background-color: $paginacao-selected-background-color !important;
  color: $paginacao-selected-text-font-color !important;
}
.paginacao-shortcut {
  background-color: $paginacao-shortcut-background-color !important;
  color: $paginacao-shortcut-text-font-color !important;
}
.paginacao-link-disabled {
  background-color: $white !important;
  color: $disabled-gray !important;
  cursor: default !important;
}

.tr-header {
  border: 0 !important;
  font-family: $label-font-family;
  font-weight: $label-font-weight;
  font-size: $lg-default-font-multiplier * $label-font-size;
  color: $label-font-color;
  line-height: $lg-default-font-multiplier * $label-line-height;
  letter-spacing: $label-letter-spacing;

  @include media-breakpoint-up(xl) {    
    font-size: $label-font-size;
    line-height: $label-line-height;
  }
}
%tr-base {
  font-family: $data-font-family;
  font-weight: $data-font-weight;
  font-size: $lg-default-font-multiplier * $data-font-size;
  color: $data-font-color;

  @include media-breakpoint-up(xl) {    
    font-size: $data-font-size;
  }
}
.tr-even {
  @extend %tr-base;
  background-color: $really-light-blue;
}
.tr-even-adm {
  @extend %tr-base;
  background-color: #fdf0f1;
}
.tr-odd {
  @extend %tr-base;
}

.medium-row {
  min-height: $table-row-medium-height;
}
.large-row {
  min-height: $table-row-large-height;
}
.cabecalho-matriz {
  font-size: 14px !important;
}
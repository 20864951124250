@import "variables";

.MuiSwitch-colorSecondary.Mui-checked{
  color: $brand-primary-1 !important;
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track{
  color: $brand-primary-1 !important;
  background-color: $brand-primary-1 !important;
}
.label-input {
  font-size: $lg-default-font-multiplier * $input-label-font-size !important;
  font-family: $input-label-font-family !important;
  color: $white !important;
  font-weight: $input-label-font-weight !important;
  margin-top: 19px !important;
  margin-left: 12px !important;

  @include media-breakpoint-up(xl) {
    font-size: $input-label-font-size !important;
  }
}
.label-input-shrink {
  transform: translate(14px, -6px) scale(0.75) !important;
}
.label-switch {
  align-items: flex-start !important;
  margin-left: 24px !important;
  margin-top: 24px !important;
}
.label-switch-text {
  font-size: $lg-default-font-multiplier * $switch-label-font-size !important;
  font-family: $switch-label-font-family !important;
  color: $switch-label-font-color !important;
  font-weight: $switch-label-font-weight !important;

  @include media-breakpoint-up(xl) {
    font-size: $switch-label-font-size !important;
  }
}
.switch-value {
  font-size: $lg-default-font-multiplier * $switch-value-font-size !important;
  font-family: $switch-value-font-family !important;
  color: $switch-value-font-color !important;
  font-weight: $switch-value-font-weight !important;

  @include media-breakpoint-up(xl) {
    font-size: $switch-value-font-size !important;
  }
}
.radio-value {
  font-size: $lg-default-font-multiplier * $radio-value-font-size !important;

  @include media-breakpoint-up(xl) {
    font-size: $radio-value-font-size !important;
  }
}
.label-switch-top {
  margin-bottom: 0 !important;
}
.switch-placement {
  margin-left: -14px;
  margin-top: -6px;
}
.switch-placement-end {
  margin-left: -14px;
  margin-top: -13px;
}
.MuiSelect-iconFilled{
  color: $input-text-font-color !important;
}
.textfield-search {
  font-family: $input-text-font-family !important;
  font-size: $lg-default-font-multiplier * $input-text-font-size !important;
  color: $almost-black !important;
  font-weight: $input-text-font-weight !important;
  padding-left: $input-text-padding-left !important;
  margin-top: 20px !important;
  background-color: $white !important;

  @include media-breakpoint-up(xl) {
    font-size: $input-text-font-size !important;
  }
}
.base-textfield {
  font-family: $input-text-font-family !important;
  font-size: $lg-default-font-multiplier * $input-text-font-size !important;
  font-weight: $input-text-font-weight !important;
  padding-left: $input-text-padding-left !important;

  @include media-breakpoint-up(xl) {
	font-size: $input-text-font-size !important;
  }
}
.textfield-regular {
  font-family: $input-text-font-family !important;
  font-size: $lg-default-font-multiplier * $input-text-font-size !important;
  color: $input-text-font-color !important;
  font-weight: $input-text-font-weight !important;
  padding-left: $input-text-padding-left !important;
  margin-top: 20px !important;
  background-color: $almost-black !important;

  @include media-breakpoint-up(xl) {
    font-size: $input-text-font-size !important;
  }
}
.MuiInputBase-input:-webkit-autofill {
  transition-delay: 9999s;
}
.select-submenu-item{
  font-size: 20px !important;
}
.textfield-undeline::after {
  border-color: $brand-primary-2 !important;
  border-width: 3px !important;
}
.textfield-datepicker-disabled {
  color: #000000 !important;
}
.textfield-datepicker-disabled::before {
  border-bottom-style: solid !important;
}
.label-error {
  color: $coral !important;
  font-family: $base-font-family !important;
}
.textfield-undeline-error::after {
  border-color: $coral !important;
  border-width: 3px !important;
}
.textfield-undeline-error::after {
  border-color: $coral !important;
  border-width: 3px !important;
}
.menu-item-input-select {
  font-family: $input-text-font-family !important;
  font-size: $lg-default-font-multiplier * $input-text-font-size !important;
  color: $input-text-font-color !important;
  font-weight: $input-label-font-weight !important;

  @include media-breakpoint-up(xl) {
    font-size: $input-text-font-size !important;
  }
}
.select-input {
  font-family: $input-text-font-family !important;
  font-size: $lg-default-font-multiplier * $input-text-font-size !important;
  color: $input-text-font-color !important;
  font-weight: $input-text-font-weight !important;
  padding-left: $select-padding-left !important;
  margin-top: 20px !important;
  background-color: $almost-black !important;

  @include media-breakpoint-up(xl) {
    font-size: $input-text-font-size !important;
    margin-top: 20px !important;
  }
}
.select-underline::after {
  border-color: $brand-primary-2 !important;
  border-width: 3px !important;
}
.radio-base {
  padding: 0 12px;
}
.radio-group {
  margin-top: 20px;
}
.checked-radio-button {
  height: 20px;
  width: 20px;
  border-radius: 20px;
  border: 5px solid $gray-200;
  background-image: $brand-primary-gradient;
}
.base-radio-button {
  height: 20px;
  width: 20px;
  border-radius: 20px;
  border: 5px solid $gray-200;
  background-color: $white;
}
.fix-position {
  top: 25px !important;
  left: 25px !important;
  max-height: none !important;
}
.select-fixer {
  height: 21px !important;
  min-height: unset !important;
}
.helper-text {
  font-family: 'Montserrat', sans-serif;
}
.dropzone {
  min-height: 60px;
  border-radius: 5px;
  border-color: $brand-secondary-1;
  border-style: dashed;
  border-width: 1px;
  background-color: $really-light-blue;
  cursor: pointer;
  outline: 0 !important;
}
.dropzone-disabled {
  cursor: not-allowed !important;
}
.dropzone-title {
  font-family: $base-font-family;
  font-size: $medium-text-font-size;
  color: $brand-secondary-1;
  font-weight: 500;
  text-align: center;
}
.dropzone-subtitle {
  font-family: $base-font-family;
  font-size: $small-text-font-size;
  color: $brand-secondary-1;
  font-weight: 400;
  text-align: center;
}
.dropzone-icon {
  font-size: 30px !important;
}
.file-thumb {
  padding: 4px;
}
.file-thumb-icon {
  font-size: 26px;
  color: $brand-secondary-1;
}
.file-thumb-name {
  font-size: 14px;
}
.file-thumb-size {
  font-size: 10px;
  margin-top: -4px;
}
.file-thumb-delete {
  font-size: 30px !important;
  color: $dark-red
}

.card-date-picker {
  width: 174px;
}
.otpTextfieldContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media(max-width: 470px) {
  .otpTextfieldContainer div {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin: 5px;
    backgroundColor: #3a3f53;
    margin-top: 20px !important;
    background-color: $almost-black !important;
    width: 30px !important;
    height: 30px !important;
    @include media-breakpoint-up(xl) {
      font-size: $input-text-font-size !important;
    }
  }
}
@media(min-width: 471px) {
  .otpTextfieldContainer div {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin: 10px;
    backgroundColor: #3a3f53;
    margin-top: 20px !important;
    background-color: $almost-black !important;
    width: 50px !important;
    height: 50px !important;
    @include media-breakpoint-up(xl) {
      font-size: $input-text-font-size !important;
    }
  }
}
@media(min-width: 471px) {
  .otpTextfieldContainer div input {
    font-family: $input-text-font-family !important;
    font-size: 30px !important;
    font-color: white !important;
    color: $input-text-font-color !important;
    border: none;
    outline: none;
    position: relative;
    padding: 0 !important;
    display: block;
    margin: auto !important;
  }
}
@media(max-width: 470px) {
  .otpTextfieldContainer div input {
    font-family: $input-text-font-family !important;
    font-size: 18px !important;
    font-color: white !important;
    color: $input-text-font-color !important;
    border: none;
    outline: none;
    position: relative;
    padding: 0 !important;
    display: block;
    margin: auto !important;
  }
}

.wysiwyg-wrapper {
  border-bottom-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid $almost-black;
}
.wysiwyg-editor {
  padding: 0 0.75rem;
  height: 300px !important;
}
.public-DraftStyleDefault-ol, .public-DraftStyleDefault-ul {
  margin: 0 !important;
}
.public-DraftStyleDefault-block {
  margin: 0 !important;
}
.chip-text {
  font-family: $input-text-font-family !important;
  font-size: $lg-default-font-multiplier * $input-text-font-size !important;
  font-weight: $input-text-font-weight !important;

  @include media-breakpoint-up(xl) {
	font-size: $input-text-font-size !important;
  }
}

.chip {
  background-color: $input-text-font-color !important;
  opacity: 1 !important;
}

.wysiwyg-color-picker {
  width: 300px !important;
  height: 200px !important;
}
.rdw-image-modal-btn, .rdw-link-modal-btn {
  width: auto !important;
}
.wysiwyg-link {
  width: 300px !important;
  height: 250px !important;
}

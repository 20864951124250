@import "colors";
@import "vendor/bootstrap/variables";

$lg-default-font-multiplier: 0.85;
$lg-card-title-font-multiplier: 0.82;
$lg-colored-card-data-font-multiplier: 0.80;
$lg-bigger-contract-value-font-multiplier: 0.80;
$lg-label-font-multiplier: 0.78;
$lg-button-font-multiplier: 0.78;
$lg-data-label-font-multiplier: 0.80;
$lg-logo-size-multiplier: 0.80;
$lg-colored-card-height: 260px;
$lg-colored-medium-card-height: 157px;
$lg-colored-mini-card-height: 87px;

$animation-duration: 0ms;
$animation-style: ease-in-out;

$base-font-family: Montserrat;

$title-font-family: $base-font-family;
$title-font-color: $almost-black;
$title-font-size: 32px; //60

$label-font-family: $base-font-family;
$label-font-weight: 700;
$label-font-size: 12px;
$label-font-color: $almost-black;
$label-letter-spacing: 2.4px;
$label-line-height: 1.25;

$data-font-family: $base-font-family;
$data-font-weight: 300;
$data-font-size: 14px;
$data-font-color: $almost-black;

$data-label-font-family: $base-font-family;
$data-label-font-weight: 300;
$data-label-font-size: 20px;
$data-label-font-color: $almost-black;
$data-label-line-height: 1.2;

$basic-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
$inverted-shadow: 3px 0 6px 0 rgba(0, 0, 0, 0.16);

$spacing-between-rows: $grid-gutter-width;
$header-body-spacing: 10px;

// header
$z-index-header: 100;

$header-height: 64px;
$header-item-spacing: 26px;

$filter-height: 135px;

$back-button-size: 45px;

$menu-item-font-size: 14px;
$menu-item-font-weight: 700;
$menu-item-font-color: $white;
$menu-item-selected-font-size: 14px;
$menu-item-selected-font-weight: 700;

$filter-panel-section-title-font-family: $base-font-family;
$filter-panel-section-title-font-size: 14px;
$filter-panel-section-title-font-weight: 700;
$filter-panel-section-title-font-color: $gray-600;

// highlights
$card-title-font-family: $title-font-family;
$card-title-font-size: 24px;
$card-title-font-size-small: 20px;
$card-title-font-color: $brand-secondary-1;
$card-title-line-height: 1.6em;
$card-title-font-weight: 400;

$card-subtitle-font-family: $base-font-family;
$card-subtitle-font-size: 18px;
$card-subtitle-font-color: $almost-black;
$card-subtitle-font-weight: 300;

$card-subtitle-form-font-size: 25px;
$card-subtitle-form-font-weight: 600;

$colored-card-height: 280px;
$colored-medium-card-height: 164px;
$colored-mini-card-height: 100px;
$colored-card-font-color: $white;
$colored-card-data-font-size: 20px;
$colored-card-data-line-height: 1.2em;
$colored-card-data-font-weight: 700;
$colored-card-text-font-size: 18px;
$colored-card-text-font-weight: 300;

$bigger-contracts-text-font-family: $base-font-family;
$bigger-contracts-text-font-size: 14px;
$bigger-contracts-text-font-color: $almost-black;
$bigger-contracts-text-font-weight: 300;

$bigger-contracts-value-font-family: $base-font-family;
$bigger-contracts-value-font-size: 16px;
$bigger-contracts-value-font-color: $dark-green;
$bigger-contracts-value-font-weight: 700;

// table
$table-row-medium-height: 38px;
$table-row-large-height: 73px;

// table-footer
$paginacao-background-color: $white;
$paginacao-button-size: 2em;
$paginacao-text-font-size: 14px;
$paginacao-text-font-weight: 300;
$paginacao-text-font-color: $almost-black;
$paginacao-selected-background-color: $brand-secondary-2;
$paginacao-selected-text-font-color: $white;
$paginacao-shortcut-background-color : $really-light-blue;
$paginacao-shortcut-text-font-color : $brand-secondary-2;

// inputs
$input-label-font-size: 16px;
$input-label-font-family: $base-font-family;
$input-label-font-color: $light-gray;
$input-label-font-weight: 600;

$input-text-font-size: 18px;
$input-text-font-family: $base-font-family;
$input-text-font-color: $light-gray;
$input-text-background-color: $almost-black;
$input-text-font-weight: 500;
$input-text-padding-left: 12px;
$input-icon-font-color: $dark-gray;

$select-padding-left: 13px;

$switch-label-font-size: 12px;
$switch-label-font-family: $base-font-family;
$switch-label-font-color: $almost-black;
$switch-label-font-weight: 600;

$switch-value-font-size: 14px;
$switch-value-font-family: $base-font-family;
$switch-value-font-color: $almost-black;
$switch-value-font-weight: 300;
$radio-value-font-size: 15px;

// buttons
$button-font-family: $base-font-family;
$button-font-size: 18px;
$button-font-color: $white;
$button-background-color: $brand-primary-1;
$button-background-color-gradient: $button-gradient;
$button-font-weight: 600;

// progress-bar
$progress-bar-height: 20px;
$progress-bar-default-color: $gray-300;
$progress-bar-ok-color: $mild-green;
$progress-bar-target-color: $mild-yellow;

$progress-bar-legend-circle-size: 12px;

$progress-bar-legend-text-font-size: 14px;

$progress-bar-legend-value-font-family: $base-font-family;
$progress-bar-legend-value-font-size: 12px;
$progress-bar-legend-value-font-color: $dark-green;
$progress-bar-legend-value-font-weight: 700;

// chevron
$chevron-height: 50px;
$chevron-item-closed-color: #77b287;
$chevron-item-open-color: #6c99bf;
$chevron-item-suspended-color: $disabled-gray;
$chevron-title-text-family: $base-font-family;
$chevron-title-text-size: 12px;
$chevron-title-text-color: $white;
$chevron-title-text-weight: 700;
$chevron-subtitle-text-family: $base-font-family;
$chevron-subtitle-text-size: 11px;
$chevron-subtitle-text-color: $white;
$chevron-subtitle-text-weight: 300;
$chevron-legend-text-font-size: 14px;
$chevron-legend-text-small-font-size: 12px;

// activity
$activity-title-font-family: $base-font-family;
$activity-title-font-size: 18px;
$activity-title-font-color: $almost-black;
$activity-title-font-weight: 500;

$activity-date-font-family: $base-font-family;
$activity-date-font-size: 14px;
$activity-date-font-color: $almost-black;
$activity-date-font-weight: 300;

$activity-text-font-family: $base-font-family;
$activity-text-font-size: 16px;
$activity-text-font-color: $almost-black;
$activity-text-font-weight: 300;

$activity-partner-font-family: $base-font-family;
$activity-partner-font-size: 16px;
$activity-partner-font-color: $brand-secondary-1;
$activity-partner-font-weight: 300;

$activity-action-button-color: $button-background-color-gradient;
$activity-disabled-button-color: $disabled-gray;

// timeline
$comment-title-font-family: $base-font-family;
$comment-title-font-size: 18px;
$comment-title-font-color: $almost-black;
$comment-title-font-weight: 500;

$comment-date-font-family: $base-font-family;
$comment-date-font-size: 14px;
$comment-date-font-color: $almost-black;
$comment-date-font-weight: 300;

$comment-author-font-family: $base-font-family;
$comment-author-font-size: 14px;
$comment-author-font-color: $almost-black;
$comment-author-font-weight: 300;

$comment-content-font-family: $base-font-family;
$comment-content-font-size: 16px;
$comment-content-font-color: $almost-black;
$comment-content-font-weight: 300;

// chart
$chart-legend-circle-size: 20px;
$chart-legend-circle-small-size: 14px;

$chart-legend-text-font-family: $base-font-family;
$chart-legend-text-font-size: 17px;
$chart-legend-text-small-font-size: 14px;
$chart-legend-text-font-color: $almost-black;
$chart-legend-text-font-weight: 300;

$chart-label-text-font-family: $base-font-family;
$chart-label-text-font-size: 17px;
$chart-label-text-small-font-size: 14px;
$chart-label-text-font-color: $almost-black;
$chart-label-text-font-weight: 300;

$chart-label-main-font-family: $base-font-family;
$chart-label-main-font-size: 24px;
$chart-label-main-small-font-size: 18px;
$chart-label-main-font-color: $almost-black;
$chart-label-main-font-weight: 300;

//Menu Proposta
$menu-text-font-family: $base-font-family;
$menu-text-font-size-small: 8px;
$menu-text-font-size: 14px;
$menu-text-font-color: $white;
$menu-text-font-weight: 600;

$toast-text-font-family: $base-font-family;
$toast-text-font-size: 14px;
$toast-text-font-weight: 400;

$icon-small-font-size: 20px;
$medium-text-font-size: 16px;
$small-text-font-size: 14px;

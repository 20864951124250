@import "styles/vendor/bootstrap/bootstrap-reboot";
@import "styles/vendor/bootstrap/bootstrap-grid";
@import "styles/vendor/bootstrap/bootstrap";

@import "styles/fonts";

@import 'styles/base';
@import 'styles/buttons';
@import 'styles/header';
@import 'styles/content';
@import 'styles/table';
@import 'styles/buttons';
@import 'styles/inputs';
@import 'styles/charts';

@import '../node_modules/file-icon-vectors/dist/file-icon-classic.min.css';

@import "variables";

// PROGRESS BAR
.progress-bar-custom {
  position: relative;
  height: $progress-bar-height;
  width: 100%;
  border-radius: 50px;
  background-color: $progress-bar-default-color;
}
.progress-bar-text {
  font-family: $label-font-family;
  font-weight: $label-font-weight;
  font-size: $label-font-size;
  color: $label-font-color;
  line-height: $label-line-height;
  letter-spacing: $label-letter-spacing;
}
.left-border-dash {
  border-left: 1px dashed $almost-black;
  height: 25px;
  padding-left: 5px;
}
.right-border-dash {
  border-right: 1px dashed $almost-black;
  height: 25px;
  padding-right: 5px;
}
.filler {
  height: 100%;
  border-radius: inherit;
}
.filler-ok {
  background-color: $progress-bar-ok-color;
}
.filler-target {
  background-color: $progress-bar-target-color;
}
.mr-n38p {
  margin-right: -38px !important;
}
.mr-n28p {
  margin-right: -28px !important;
}
.progress-bar-legend-circle {
  height: $progress-bar-legend-circle-size;
  width: $progress-bar-legend-circle-size;
  border-radius: $progress-bar-legend-circle-size;
}
.circle-ok {
  background-color: $progress-bar-ok-color;
}
.circle-target {
  background-color: $progress-bar-target-color;
}
.circle-default {
  background-color: $progress-bar-default-color;
}
.circle-yellow {
  background-color: $progress-bar-target-color;
}
.progress-bar-legend-text {
  font-family: $data-font-family;
  font-size: $lg-default-font-multiplier * $progress-bar-legend-text-font-size;
  color: $data-font-color;
  font-weight: $data-font-weight;

  @include media-breakpoint-up(xl) {
    font-size: $progress-bar-legend-text-font-size;
  }
}
.progress-bar-legend-value {
  font-family: $progress-bar-legend-value-font-family;
  font-size: $lg-default-font-multiplier * $progress-bar-legend-value-font-size;
  color: $progress-bar-legend-value-font-color;
  font-weight: $progress-bar-legend-value-font-weight;

  @include media-breakpoint-up(xl) {
    font-size: $progress-bar-legend-value-font-size;
  }
}


// CHEVRON
.chevron {
  height: $chevron-height;
  list-style: none;
  margin-bottom: 0px;
  padding: 0;
}
.chevron-item {
  height: 100%;
  position: relative;
  margin: 0 18px;
  font-family: $chevron-title-text-family;
  font-size: $lg-default-font-multiplier * $chevron-title-text-size;
  font-weight: $chevron-title-text-weight;
  color: $chevron-title-text-color;
  line-height: $lg-default-font-multiplier * $chevron-title-text-size;

  @include media-breakpoint-up(xl) {
    font-size: $chevron-title-text-size;
    line-height: $chevron-title-text-size;
  }

  @include media-breakpoint-down(sm) {
    font-size: 7px !important;
    margin: 0 15px;
  }

}
.chevron-item span {
  margin-left: 0px;
}
.chevron-item:first-of-type span {
  margin-left: 5px;
}
.chevron-item:first-of-type {
  margin-left: 0;
}
.chevron-item:last-of-type {
  margin-right: 0;
}
.chevron-item .subtitle {
  font-family: $chevron-subtitle-text-family;
  font-size: $lg-default-font-multiplier * $chevron-subtitle-text-size;
  font-weight: $chevron-subtitle-text-weight;
  color: $chevron-subtitle-text-color;
  margin-top: -5px;
  line-height: 2.5 * $lg-default-font-multiplier * $chevron-subtitle-text-size;

  @include media-breakpoint-up(xl) {
    font-size: $chevron-subtitle-text-size;
    line-height: 2.5 * $chevron-subtitle-text-size;
  }
}
.chevron-item-E {
  background-color: $chevron-item-closed-color;
}
.chevron-item-A {
  background-color: $chevron-item-open-color;
}
.chevron-item-S {
  background-color: $chevron-item-suspended-color;
}
.chevron-item:before, .chevron-item:after {
  position: absolute;
  content:"";
  top:0;
  width:0;
  height:0;
}
.chevron-item:last-of-type:after {
  border: 0;
}
.chevron-item:after {
  left:100%;
  border: 25px solid transparent;
}
.chevron-item-A:after {
  border-left-color: $chevron-item-open-color;
  border-right-width: 0;
}
.chevron-item-E:after {
  border-left-color: $chevron-item-closed-color;
  border-right-width: 0;
}
.chevron-item-S:after {
  border-left-color: $chevron-item-suspended-color;
  border-right-width: 0;
}
.chevron-item:first-of-type:before {
  border: 0;
}
.chevron-item:before {
  right:calc(100%);

  @include media-breakpoint-up(xl) {
    right:calc(100%);
  }
}
.chevron-item-A:before {
  border: 25px solid $chevron-item-open-color;
  border-left-color:transparent;
  border-right-width: 0;
}
.chevron-item-E:before {
  border: 25px solid $chevron-item-closed-color;
  border-left-color:transparent;
  border-right-width: 0;
}
.chevron-item-S:before {
  border: 25px solid $chevron-item-suspended-color;
  border-left-color:transparent;
  border-right-width: 0;
}

// TIMELINE
.circle-outline {
  width: 21px;
  height: 21px;
  border-radius: 21px;
  border: 5px solid gray;
  margin-left: 2px;
  background-color: $white;
  z-index: 10;
}
.circle-container {
  margin-left: -2px;
  background-color: $white;
  z-index: 10;
}
.circle-bank {
  border-color: $dark-red;
  color: $dark-red;
}
.circle-client {
  border-color: $mild-blue;
  color: $mild-blue;
}
.circle-confidential {
  border-color: $black;
  color: $black;
}
.comment-legend-circle {
  height: $progress-bar-legend-circle-size;
  width: $progress-bar-legend-circle-size;
  border-radius: $progress-bar-legend-circle-size;
}
.comment-legend-circle-bank {
  background-color: $dark-red;
}
.comment-legend-circle-client {
  background-color: $mild-blue;
}
.comment-legend-circle-confidential {
  background-color: $black;
}
.comment-title {
  font-family: $comment-title-font-family;
  font-size: $lg-default-font-multiplier * $comment-title-font-size;
  color: $comment-title-font-color;
  font-weight: $comment-title-font-weight;

  @include media-breakpoint-up(xl) {
    font-size: $comment-title-font-size;
  }
}
.comment-title-container {
  border-left: 1px solid #bdbdbd;
  padding-left: 20px;
  margin-left: -18px;
}
.activity-title-container {
  border-left: 1px solid #bdbdbd;
  padding-left: 20px;
  margin-left: -19px;
}
.comment-content-container {
  margin-top: 0 !important;
}
.comment-date {
  font-family: $comment-date-font-family;
  font-size: $lg-default-font-multiplier * $comment-date-font-size;
  color: $comment-date-font-color;
  font-weight: $comment-date-font-weight;
  margin-top: 5px;

  @include media-breakpoint-up(xl) {
    font-size: $comment-date-font-size;
  }
}
.comment-date:first-letter {
  text-transform: capitalize;
}
.comment-author {
  font-family: $comment-author-font-family;
  font-size: $lg-default-font-multiplier * $comment-author-font-size;
  color: $comment-author-font-color;
  font-weight: $comment-author-font-weight;

  @include media-breakpoint-up(xl) {
    font-size: $comment-author-font-size;
  }
}
.comment-content {
  white-space: pre-wrap;
  font-family: $comment-content-font-family;
  font-size: $lg-default-font-multiplier * $comment-content-font-size;
  color: $comment-content-font-color;
  font-weight: $comment-content-font-weight;

  @include media-breakpoint-up(xl) {
    font-size: $comment-content-font-size;
  }
}

.past-activities-legend-circle {
  height: $progress-bar-legend-circle-size;
  width: $progress-bar-legend-circle-size;
  border-radius: $progress-bar-legend-circle-size;
}
.past-activities-legend-circle-approved {
  background-color: $dark-green;
}
.past-activities-legend-circle-refused {
  background-color: $dark-red;
}
.past-activities-legend-circle-others {
  background-color: $disabled-gray;
}
.circle-approved {
  border-color: $dark-green;
}
.circle-refused {
  border-color: $dark-red;
}
.circle-others {
  border-color: $disabled-gray;
}
.circle-E {
  border-color: $chevron-item-closed-color;
}
.circle-A {
  border-color: $chevron-item-open-color;
}
.circle-S {
  border-color: $light-gray;
}
.chart-legend-circle {
  height: $lg-default-font-multiplier * $chart-legend-circle-size;
  width: $lg-default-font-multiplier * $chart-legend-circle-size;
  border-radius: $lg-default-font-multiplier * $chart-legend-circle-size;

  @include media-breakpoint-up(xl) {
    height: $chart-legend-circle-size;
    width: $chart-legend-circle-size;
    border-radius: $chart-legend-circle-size;
  }
}
.chart-legend-circle-small {
  height: $lg-default-font-multiplier * $chart-legend-circle-small-size;
  width: $lg-default-font-multiplier * $chart-legend-circle-small-size;
  border-radius: $lg-default-font-multiplier * $chart-legend-circle-small-size;

  @include media-breakpoint-up(xl) {
    height: $chart-legend-circle-small-size;
    width: $chart-legend-circle-small-size;
    border-radius: $chart-legend-circle-small-size;
  }
}

.chevron-legend-text {
  font-family: $chart-legend-text-font-family;
  font-size: $lg-default-font-multiplier * $chevron-legend-text-font-size;
  color: $chart-legend-text-font-color;
  font-weight: $chart-legend-text-font-weight;
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @include media-breakpoint-up(xl) {
    font-size: $chevron-legend-text-font-size;
    width: auto;
  }
}

.chart-legend-text {
  font-family: $chart-legend-text-font-family;
  font-size: $lg-default-font-multiplier * $chart-legend-text-font-size;
  color: $chart-legend-text-font-color;
  font-weight: $chart-legend-text-font-weight;
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @include media-breakpoint-up(xl) {
    font-size: $chart-legend-text-font-size;
    width: auto;
  }
}
.chart-legend-text-small {
  font-family: $chart-legend-text-font-family;
  font-size: $lg-default-font-multiplier * $chart-legend-text-small-font-size;
  color: $chart-legend-text-font-color;
  font-weight: $chart-legend-text-font-weight;
  max-width: 90%;
  width: 125px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @include media-breakpoint-up(xl) {
    font-size: $chart-legend-text-small-font-size;
    width: 215px;
  }
}
.chart-legend-text-small-bar {
  font-family: $chart-legend-text-font-family;
  font-size: $lg-default-font-multiplier * $chart-legend-text-small-font-size;
  color: $chart-legend-text-font-color;
  font-weight: $chart-legend-text-font-weight;

  @include media-breakpoint-up(xl) {
    font-size: $chart-legend-text-small-font-size;
  }
}

.chart-label-text{
  font-family: $chart-label-text-font-family;
  font-size: $lg-default-font-multiplier * $chart-label-text-font-size;
  color: $chart-label-text-font-color;
  font-weight: $chart-label-text-font-weight;

  @include media-breakpoint-up(xl) {
    font-size: $chart-label-text-font-size;
  }
}
.chart-label-text-small{
  font-family: $chart-label-text-font-family;
  font-size: $lg-default-font-multiplier * $chart-label-text-small-font-size;
  color: $chart-label-text-font-color;
  font-weight: $chart-label-text-font-weight;

  @include media-breakpoint-up(xl) {
    font-size: $chart-label-text-small-font-size;
  }
}

.chart-label-main {
  font-family: $chart-label-main-font-family;
  font-size: $lg-default-font-multiplier * $chart-label-main-font-size;
  color: $chart-label-main-font-color;
  font-weight: $chart-label-main-font-weight;

  @include media-breakpoint-up(xl) {
    font-size: $chart-label-main-font-size;
  }
}
.chart-label-main-small {
  font-family: $chart-label-main-font-family;
  font-size: $lg-default-font-multiplier * $chart-label-main-small-font-size;
  color: $chart-label-main-font-color;
  font-weight: $chart-label-main-font-weight;

  @include media-breakpoint-up(xl) {
    font-size: $chart-label-main-small-font-size;
  }
}


.recharts-pie-sector {
  cursor: pointer;
}
.label-central {
  cursor: pointer;
}

.donut-chart-animation-enter {
  transform: translateY(-135px);
}
.donut-chart-animation-enter-active {
  transform-origin: 50% 0;
  transform: translateY(0);
  transition: all $animation-duration ease-in-out;
}
.donut-chart-animation-enter-done {
  transform-origin: 50% 0;
  transform: translateY(0);
}
.donut-chart-animation-exit {
  transform: translateY(0);
  transform-origin: 50% 0;
}
.donut-chart-animation-exit-active {
  transform: translateY(-135px);
  transform-origin: 0 0;
  transition: all $animation-duration $animation-style;
}

.container-chart-animation-enter {
  flex: 0 0 100%;
  max-width: 100%;
  margin-left: 0;
}
.container-chart-animation-enter-active {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
  margin-left: 16.66667%;
  transition: all $animation-duration $animation-style;
}
.container-chart-animation-enter-done {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
  margin-left: 16.66667%;
}
.container-chart-animation-exit {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
  margin-left: 16.66667%;
}
.container-chart-animation-exit-active {
  flex: 0 0 100%;
  max-width: 100%;
  margin-left: 0;
  transition: all $animation-duration $animation-style;
}
.container-chart-animation-exit-done {
  flex: 0 0 100%;
  max-width: 100%;
  margin-left: 0;
}

.big-chart {
  height: calc(100vh - #{$header-height} - 30px - 5*#{$grid-gutter-width});
  transition: all $animation-duration $animation-style;
}
.almost-big-chart {
  height: calc((100vh - #{$header-height} - 30px - 5*#{$grid-gutter-width}) * 0.75);
  transition: all $animation-duration $animation-style;
}
.small-chart {
  height: 335px;
}
.recharts-cartesian-axis-tick-value {
  font-size: 14px;
}
.legenda-meta {
  font-size: 12px;
  font-weight: 300;
}

@media (max-width: 380px) {
    .responsividade {
      display: flex;
      flex-direction: column;
    }
    b {
        display: block;
    }
    .none {
      display: none;
    }
    .MuiAccordionSummary-content{
      font-size: .70rem!important;
    }
  }
@media (max-width: 415px) {
  .responsividade {
    display: flex;
    flex-direction: column;
  }
  b {
    display: block;
  }
  .none {
    display: none;
  }
  .MuiAccordionSummary-content {
    font-size: .70rem!important;
  }
}
@media (max-width: 770px) {
  .MuiAccordionSummary-content {
    font-size: .72rem!important;
  }
}  
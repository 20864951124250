@import "variables";

.header {
  background-image: $brand-primary-gradient;
}
.logo-header {
  width: 92px;
  height: 28px;
}
.user-text {
  font-family: $base-font-family;
  color: $menu-item-font-color;
  font-size: $menu-item-font-size;
  text-transform: none !important;
}
.vertical-separator {
  border-left: 1px solid $menu-item-font-color;
  margin-left: $spacer * 0.5;
  margin-right: $spacer * 0.5;
  height: 36px;
}
.menu-item {
  font-family: $base-font-family;
  font-weight: $menu-item-font-weight !important;
  margin-left: 0.5 * $header-item-spacing !important;
  margin-right: 0.5 * $header-item-spacing !important;
  color: $menu-item-font-color !important;
  font-size: $menu-item-font-size !important;
  text-transform: none !important;
}
.menu-item.selected {
  font-weight: $menu-item-selected-font-weight;
  border-bottom: 1px solid $menu-item-font-color;
  font-size: $lg-logo-size-multiplier * $menu-item-selected-font-size;

  @include media-breakpoint-up(xl) {
    font-size: $menu-item-selected-font-size;
  }
}
.submenu-item {
  font-family: $input-text-font-family !important;
  font-size: $menu-item-font-size !important;
}
.menu-icon {
  margin-left: $lg-logo-size-multiplier * 8px;
  margin-right: $lg-logo-size-multiplier * 8px;

  @include media-breakpoint-up(xl) {
    margin-left: 8px;
    margin-right: 8px;
  }
}
.menu-icon-font {
  color: $white;
  font-size: $lg-logo-size-multiplier * 32px !important;

  @include media-breakpoint-up(xl) {
    font-size: 32px !important;
  }
}
.drawer-small {
  width: 280px
}
.drawer-item {
  font-family: $base-font-family !important;
  font-weight: $menu-item-font-weight !important;
}
.drawer-item-1 {
  padding-left: $spacer*0.5
}
.drawer-item-2 {
  padding-left: $spacer
}
.drawer-item-3 {
  padding-left: $spacer*1.5
}
.drawer-header {
  font-weight: 700;
  margin: 2*$spacer $spacer;
}

.badge {
  background-color: $brand-secondary-1;
  font-family: $base-font-family !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: $white;
}
.arrow {
  position: absolute;
  font-size: 7px;
  width: 3em;
  height: 3em;
  z-index: 1 !important;
}
.arrow::before {
  content: '';
  margin: auto;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
}
.popup {
  z-index: 100;
}
.popup[x-placement*="bottom"] .arrow {
  top: 0;
  left: 0;
  margin-top: -0.9em;
  width: 3em;
  height: 1em;

  &::before {
    border-width: 0 1em 1em 1em;
    border-color: transparent transparent white transparent;
  }
}
.popup[x-placement*="top"] .arrow {
  bottom: 0;
  left: 0;
  margin-bottom: -0.9em;
  width: 3em;
  height: 1em;

  &::before {
    border-width: 1em 1em 0 1em;
    border-color: white transparent transparent transparent;
  }
}
.popup[x-placement*="right"] .arrow {
  left: 0;
  margin-left: -0.9em;
  height: 3em;
  width: 1em;

  &::before {
    border-width: 1em 1em 1em 0;
    border-color: transparent white transparent transparent;
  }
}
.popup[x-placement*="left"] .arrow {
  right: 0;
  margin-right: -0.9em;
  height: 3em;
  width: 1em;

  &::before {
    border-width: 1em 0 1em 1em;
    border-color: transparent transparent transparent white;
  }
}
.search-popup-container {
  width: 500px;
}
.filter-panel-container {
  width: 100%;
  position: relative;
  margin-top: $header-height;
}
.filter-panel {
  width: 100%;
  height: $filter-height;
  position: relative;
  z-index: $z-index-header - 1;
  background-color: white;
  box-shadow: $basic-shadow;
}
.filter-panel-icon {
  margin-left: 5px;
}
.filter-panel-tab {
  background-color: white;
  z-index: $z-index-header - 2;
  position: relative;
  box-shadow: $basic-shadow;
  padding: 5px 80px;
  font-family: $base-font-family;
  font-size: 14px;
  font-weight: 500;
  color: $almost-black;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.filter-panel-section {
  padding-top: 5px;
}
.filter-panel-section-title {
  font-size: $filter-panel-section-title-font-size;
  font-weight: $filter-panel-section-title-font-weight;
  font-family: $filter-panel-section-title-font-family;
  color: $filter-panel-section-title-font-color;
}
.filter-tab-animation-enter {
  transform: translateY(-$filter-height);
}
.filter-tab-animation-enter-active {
  transform: translateY(0);
  transition: all $animation-duration $animation-style;
}
.filter-tab-animation-exit {
  transform: translateY(0);
}
.filter-tab-animation-exit-active {
  transform: translateY(-$filter-height);
  transition: all $animation-duration $animation-style;
}

.filter-label-width {
  width: 100px;
}
.filter-label-height {
  height: 32px;
  line-height: 32px;
}
.filter-item-badge {
  height: 24px !important;
}
.filter-item-text {
  font-family: $base-font-family;
  font-weight: 300;
  font-size: 14px;
  color: $almost-black;
}
.empty-label-filter {
  font-family: $base-font-family;
  font-weight: 300;
  font-size: 14px;
  color: $almost-black;
}
.empty-label-filter:hover {
  text-decoration: underline;
}

@media (max-width: 380px) {
  .search-popup-container {
    width: 340px;
  }
  .MuiInputBase-root{
    font-size: .69rem!important;
  }
}
@media (max-width: 415px) {
  .search-popup-container {
    width: 340px;
  }
  .MuiInputBase-root{
    font-size: .69rem!important;
  }
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'),
  local('Montserrat-Regular'),
  url('fonts/Montserrat-Regular.woff2') format('woff2'),
  url('fonts/Montserrat-Regular.woff') format('woff'),
  url('fonts/Montserrat-Regular.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local('Montserrat Medium'),
  local('Montserrat-Medium'),
  url('fonts/Montserrat-Medium.woff2') format('woff2'),
  url('fonts/Montserrat-Medium.woff') format('woff'),
  url('fonts/Montserrat-Medium.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local('Montserrat Semibold'),
  local('Montserrat-Semibold'),
  url('fonts/Montserrat-SemiBold.woff2') format('woff2'),
  url('fonts/Montserrat-SemiBold.woff') format('woff'),
  url('fonts/Montserrat-SemiBold.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'),
  local('Montserrat-Bold'),
  url('fonts/Montserrat-Bold.woff2') format('woff2'),
  url('fonts/Montserrat-Bold.woff') format('woff'),
  url('fonts/Montserrat-Bold.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

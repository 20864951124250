@import "variables";

.pd-left-radio{
  padding-left: 35px;
}
.pd-top-switch{
  padding-top: 10px;
}
.mini-separator {
  width: 25%;
  border: solid $separator 2px;
  margin: 2*$spacer auto;
}
.big-separator {
  width: 95%;
  border: solid $separator 1px;
  margin: 2*$spacer auto;
}
.esqueci-senha {
  font-size: $small-text-font-size;
}
.instrucoes {
  font-size: $small-text-font-size;
}
.resumir-texto {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.title-table-dashboard{
  font-size: 10px !important;
  font-weight: 500 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include media-breakpoint-up(md) {
    font-size: 14px !important;
  }
}
.sla-vencido{
  color: $dark-red !important;
  font-weight: 500 !important;
}
.color-concluido{
  color: $dark-green !important;
}
.producao-baixa{
  color: $dark-red !important;
}
.producao-alta{
  color: $dark-blue !important;
}
.page-title {
  font-family: $title-font-family;
  font-size: $lg-default-font-multiplier * $title-font-size;
  color: $title-font-color;

  @include media-breakpoint-up(xl) {
    font-size: $title-font-size;
  }
}

.sub-title {
  font-family: $card-title-font-family;
  font-size: $lg-card-title-font-multiplier * $card-title-font-size;
  line-height: $card-title-line-height;
  color: $brand-secondary-1;

  @include media-breakpoint-up(xl) {
    font-size: $card-title-font-size;
  }
}

.sub-title-form {
  font-family: $card-title-font-family;
  font-size: $lg-card-title-font-multiplier * $card-subtitle-form-font-size;
  line-height: $card-title-line-height;
  color: $brand-secondary-1;
  font-weight: $card-subtitle-form-font-weight;

  @include media-breakpoint-up(xl) {
    font-size: $card-subtitle-form-font-size;
  }
}

// CARDS
.card {
  border: 1px solid $light-gray;
  border-radius: 5px !important;
  box-shadow: none !important;
}
.card-title-dashboard {
  font-family: $card-title-font-family;
  font-size: $lg-card-title-font-multiplier * 20px;
  line-height: $lg-card-title-font-multiplier * $card-title-line-height;
  font-weight: $card-title-font-weight;

  @include media-breakpoint-up(xl) {
    font-size: 20px;
    line-height: $card-title-line-height;
  }
}

.card-title {
  font-family: $card-title-font-family;
  font-size: $lg-card-title-font-multiplier * $card-title-font-size;
  line-height: $lg-card-title-font-multiplier * $card-title-line-height;
  font-weight: $card-title-font-weight;

  @include media-breakpoint-up(xl) {
    font-size: $card-title-font-size;
    line-height: $card-title-line-height;
  }
}
.card-title-small-chart {
  font-family: $card-title-font-family;
  font-size: $lg-card-title-font-multiplier * $card-title-font-size-small;
  line-height: $lg-card-title-font-multiplier * $card-title-line-height;

  @include media-breakpoint-up(xl) {
    font-size: $card-title-font-size-small;
    line-height: $card-title-line-height;
  }
}
.card-title-almost-big-chart {
  font-family: $card-title-font-family;
  font-size: $card-title-font-size;
  line-height: $card-title-line-height;
}
.card-title-big-chart {
  font-family: $card-title-font-family;
  font-size: $card-title-font-size;
  line-height: $card-title-line-height;
}
.card-title-checklist{
  font-family: $card-title-font-family;
  font-size: 18px;
  color: $dark-blue;
}
.card-subtitle-checklist{
  font-family: $card-title-font-family;
  font-size: 14px;
  font-weight: 700;
  color: $dark-blue;
}
.card-subtitle {
  font-family: $card-subtitle-font-family;
  font-size: $lg-card-title-font-multiplier * $card-subtitle-font-size;
  font-weight: $card-subtitle-font-weight;
  color: $card-subtitle-font-color;

  @include media-breakpoint-up(xl) {
    font-size: $card-subtitle-font-size;
  }
}
%colored-card {
  height: $lg-colored-card-height;
  color: $colored-card-font-color !important;

  @include media-breakpoint-up(xl) {
    height: $colored-card-height;
  }
}
.colored-card {
  @extend %colored-card;
}
.colored-card-data {
  font-size: $lg-colored-card-data-font-multiplier * $colored-card-data-font-size;
  line-height: $lg-colored-card-data-font-multiplier * $colored-card-data-line-height;
  font-weight: $colored-card-data-font-weight;

  @include media-breakpoint-up(xl) {
    font-size: $colored-card-data-font-size;
    line-height: $colored-card-data-line-height;
  }
}
.colored-card-text {
  font-size: $lg-colored-card-data-font-multiplier * $colored-card-text-font-size;
  font-weight: $colored-card-text-font-weight;

  @include media-breakpoint-up(xl) {
    font-size: $colored-card-text-font-size;
  }
}
.colored-card.contratadas-mes-anterior {
  background-image: $contratadas-mes-anterior-gradient;
}
.colored-card.contratadas-mes-atual {
  background-image: $contratadas-mes-atual-gradient;
}
.colored-card.em-contratacao {
  background-image: $em-contratacao-gradient;
  height: $lg-colored-mini-card-height;

  @include media-breakpoint-up(xl) {
    height: $colored-mini-card-height;
  }
}
.colored-card.credito-aprovado {
  background-image: $credito-aprovado-gradient;
  height: $lg-colored-medium-card-height;

  @include media-breakpoint-up(xl) {
    height: $colored-medium-card-height;
  }
}
.bigger-contract-text {
  margin-left: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 230px;

  @include media-breakpoint-up(xl) {
    width: 315px;
  }
}

.bigger-contract-value {
  font-family: $bigger-contracts-value-font-family;
  font-size: $lg-bigger-contract-value-font-multiplier * $bigger-contracts-value-font-size;
  color: $bigger-contracts-value-font-color;
  font-weight: $bigger-contracts-value-font-weight;
  margin-right: 10px;

  @include media-breakpoint-up(xl) {
    font-size: $bigger-contracts-value-font-size;
  }
}

// back button
.back-button::before,
.back-button::after {
  content: '';
  position: absolute;
  top: $header-height;
  left: 0;
  border: solid transparent;
}
.back-button::before {
  border-width: $back-button-size;
  border-left-color: transparent;
  border-top-color: transparent;
}
.back-button::after {
  border-width: $back-button-size;
  border-left-color: $brand-secondary-1;
  border-top-color: $brand-secondary-1;
}
.back-button-animation-enter::before,
.back-button-animation-enter::after {
  transform: translateY(0);
}
.back-button-animation-enter-active::before,
.back-button-animation-enter-active::after {
  transform: translateY($filter-height);
  transition: all $animation-duration $animation-style;
}
.back-button-animation-enter-done::before,
.back-button-animation-enter-done::after {
  transform: translateY($filter-height);
}
.back-button-animation-exit::before,
.back-button-animation-exit::after {
  transform: translateY($filter-height);
}
.back-button-animation-exit-active::before,
.back-button-animation-exit-active::after {
  transform: translateY(0);
  transition: all $animation-duration $animation-style;
}
.back-button-icon {
  color: $white;
  font-size: 38px !important;
  z-index: 1;
  position: absolute;
  margin-left: 5px;
  margin-top: -25px;
  top: $header-height + 35px;
}
.back-button-icon-animation-enter {
  transform: translateY(0);
}
.back-button-icon-animation-enter-active {
  transform: translateY($filter-height);
  transition: all $animation-duration $animation-style !important;
}
.back-button-icon-animation-enter-done {
  transform: translateY($filter-height);
}
.back-button-icon-animation-exit {
  transform: translateY($filter-height);
}
.back-button-icon-animation-exit-active {
  transform: translateY(0);
  transition: all $animation-duration $animation-style !important;
}

// ACTIVITIES
.activity-title {
  font-family: $activity-title-font-family;
  font-size: $lg-default-font-multiplier * $activity-title-font-size;
  color: $activity-title-font-color;
  font-weight: $activity-title-font-weight;

  @include media-breakpoint-up(xl) {
    font-size: $activity-title-font-size;
  }
}
.activity-date {
  font-family: $activity-date-font-family;
  font-size: $lg-default-font-multiplier * $activity-date-font-size;
  color: $activity-date-font-color;
  font-weight: $activity-date-font-weight;

  @include media-breakpoint-up(xl) {
    font-size: $activity-date-font-size;
  }
}
.activity-text {
  font-family: $activity-text-font-family;
  font-size: $lg-default-font-multiplier * $activity-text-font-size;
  color: $activity-text-font-color;
  font-weight: $activity-text-font-weight;

  @include media-breakpoint-up(xl) {
    font-size: $activity-text-font-size;
  }
}
.activity-partner {
  font-family: $activity-partner-font-family;
  font-size: $lg-default-font-multiplier * $activity-partner-font-size;
  color: $activity-partner-font-color;
  font-weight: $activity-partner-font-weight;

  @include media-breakpoint-up(xl) {
    font-size: $activity-partner-font-size;
  }
}

// LATERAL PANEL
.lateral-panel {
  background-size:cover;
  background: $brand-secondary-gradient-lateral-panel;
  height: 100%;
  padding: 1rem;
}
.lateral-panel-fixer {
  position: absolute;
  top: 0;
  bottom: 0;
}
.lateral-panel-divider {
  background-color: rgba(255, 255, 255, 0.5) !important;
  margin-bottom: 0 !important;
}
.lateral-panel-input-container {
  background-color: $white !important;
  height: 35px;
  border-radius: 35px !important;
}
.lateral-panel-input-text {
  padding-left: 15px !important;
  padding-bottom: 6px !important;
  font-family: $input-text-font-family !important;
  font-size: $input-text-font-size !important;
  color: $input-text-font-color !important;
  font-weight: $input-text-font-weight !important;
}
.lateral-panel-input-icon {
  margin-left: -30px !important;
  padding-left: 4px !important;
  padding-right: 6px !important;
}
.panel-item {
  color: $white;
  margin-top: 10px;
  margin-bottom: 10px;
}
.panel-item-date {
  font-family: $base-font-family;
  font-weight: 300;
  font-size: 14px;
}
.panel-item-date:first-letter {
  text-transform: capitalize;
}
.panel-item-badge {
  height: 24px !important;
}
.panel-item-badge-VD {
  background-color: $dark-green !important;
}
.panel-item-badge-VM {
  background-color: $dark-red !important;
}
.panel-item-badge-AM {
  background-color: $dark-yellow !important;
}
.panel-item-badge-RX {
  background-color: $dark-purple !important;
}
.panel-item-badge-CZ {
  background-color: $gray-700 !important;
}
.panel-item-title {
  font-family: $base-font-family;
  font-weight: 700;
  font-size: 14px;
  color: $white;
}
.panel-item-name {
  font-family: $base-font-family;
  font-weight: 500;
  font-size: 16px;
}
.panel-item-operation {
  font-family: $base-font-family;
  font-weight: 100;
  font-size: 16px;
}
.icon-info {
  font-size: 32px;
  color: $gray-600;
}
.card-change-bpo {
  width: 350px;
}
.card-add-comment {
  width: 450px;
}

// CRIM button
.crim-button::before,
.crim-button::after {
  content: '';
  position: absolute;
  top: $header-height;
  right: 0;
  border: solid transparent;
}
.crim-button::before {
  border-width: 45px;
  border-right-color: transparent;
  border-top-color: transparent;
}
.crim-button::after {
  border-width: 45px;
  border-right-color: $brand-secondary-1;
  border-top-color: $brand-secondary-1;
}
.crim-button-text {
  font-family: $base-font-family;
  color: $white;
  position: absolute;
  font-size: 18px;
  z-index: 1;
  right: 0;
  font-weight: 500;
  margin-right: 10px;
  margin-top: -5px;
}
.crim-button-icon {
  margin-right: 5px;
  margin-top: 20px;
  color: $white !important;
  position: absolute;
  right: 0;
  z-index: 1;
  font-size: 26px !important;
}

.expand-button::before,
.expand-button::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border: solid transparent;
}
.expand-button::before {
  border-width: 1.5em;
  border-right-color: transparent;
  border-top-color: transparent;
}
.expand-button::after {
  border-width: 1.5em;
  border-right-color: $brand-secondary-1;
  border-top-color: $brand-secondary-1;
}
.expand-icon {
  color: $white;
  font-size: 20px;
  z-index: 1;
  margin-right: 3px;
  margin-top: 2px
}

.delete-button::before,
.delete-button::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  border: solid transparent;
}
.delete-button::before {
  border-width: 1.5em;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.delete-button::after {
  border-width: 1.5em;
  border-right-color: $red;
  border-bottom-color: $red;
}
.visible-1s {
  animation: visible 1s forwards;
  -o-animation: visible 1s forwards;
  -ms-animation: visible 1s forwards;
  -webkit-animation: visible 1s forwards;
  -moz-animation: visible 1s forwards;
}
.delete-button-larger::before {
  animation: grow-delete-button-before 400ms forwards;
  -o-animation: grow-delete-button-before 400ms forwards;
  -ms-animation: grow-delete-button-before 400ms forwards;
  -webkit-animation: grow-delete-button-before 400ms forwards;
  -moz-animation: grow-delete-button-before 400ms forwards;
}
.delete-button-larger::after {
  animation: grow-delete-button-after 400ms forwards;
  -o-animation: grow-delete-button-after 400ms forwards;
  -ms-animation: grow-delete-button-after 400ms forwards;
  -webkit-animation: grow-delete-button-after 400ms forwards;
  -moz-animation: grow-delete-button-after 400ms forwards;
}
@keyframes visible {
  0% {
    opacity: 0;
    z-index: 0;
  }
  100% {
    opacity: 1;
    z-index: 2;
  }
}
@keyframes grow-delete-button-before {
  100% {
    border-width: 20em;
    border-right-color: transparent;
    border-bottom-color: transparent;
  }
}
@keyframes grow-delete-button-after {
  100% {
    border-width: 20em;
    border-right-color: $red;
    border-bottom-color: $red;
  }
}
.close-icon {
  color: $white;
  font-size: 20px !important;
  margin-right: 10px;
  margin-top: 10px;
  top: 0;
  right: 0;
}
.delete-icon {
  color: $white;
  font-size: 20px !important;
  z-index: 1;
  margin-right: 3px;
  margin-bottom: 3px;
  bottom: 0;
  right: 0;
}

.document-button::before,
.document-button::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border: solid transparent;
}
.document-button::before {
  border-width: 1.5em;
  border-right-color: transparent;
  border-top-color: transparent;
}
.document-button::after {
  border-width: 1.5em;
  border-right-color: $dark-green;
  border-top-color: $dark-green;
}
.document-icon {
  color: $white;
  font-size: 20px !important;
  z-index: 1;
  margin-right: 3px;
  margin-top: 3px;
  top: 0;
  right: 0;
}

.large-delete-icon {
  color: $white;
  font-size: 32px !important;
  margin-top: -8px;
  margin-bottom: 4px;
}
.delete-buyer-name {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px !important;
}
.confirm-delete-button {
  background-color: transparent !important;
  font-family: $base-font-family !important;
  font-size: 0.6 * $button-font-size !important;
  color: $button-font-color !important;
  font-weight: $button-font-weight !important;
}
.recalcular-button::before,
.recalcular-button::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 150px;
  border: solid transparent;
}
.recalcular-button::before {
  border-width: 1.5em;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.recalcular-button::after {
  border-width: 1.5em;
  border-right-color: $dark-blue;
  border-bottom-color: $dark-blue;
}
.recalcular-button-label {
  color: $white;
  font-size: 18px !important;
  font-weight: 500;
  text-transform: uppercase;
  z-index: 1;
  bottom: 0;
  right: 0;
  background-color: $dark-blue;
  width: 150px;
  height: 48px;
  padding-top: 10px;
  padding-left: 15px;
}


.container-animation-enter {
  transform: translateY(-$filter-height);
}
.container-animation-enter-active {
  transform: translateY(0);
  transition: all $animation-duration $animation-style !important;
}
.container-animation-enter-done {
  transform: translateY(0);
}
.container-animation-exit {
  transform: translateY(0);
}
.container-animation-exit-active {
  transform: translateY(-$filter-height);
  transition: all $animation-duration $animation-style !important;
}
.person-icon {
  font-size: 21px;
  color: $dark-red;
}
.waiting-colored-card-big {
  margin-top: $colored-card-height/2 - 40px;
}
.waiting-colored-card-medium {
  margin-top: $colored-medium-card-height/2 - 40px;
}
.waiting-colored-card-small {
  margin-top: $colored-mini-card-height/2 - 35px;
}

// Simulacao Desenquadramento

.desenquadrado {
  color: $dark-red !important;
}
.card-resultado-simulacao {
  height: 617px;

  @include media-breakpoint-up(xl) {
    height: 645px;
  }
}
.row-resultado-simulacao {
  height: 51px;

  @include media-breakpoint-up(xl) {
    height: 54px;
  }
}
.modal-termino {
  height: 220px;
  width: 600px;

  @include media-breakpoint-up(xl) {
    height: 250px;
  }
}

//Menu Cockpit

.menu-proposta-label {
  font-family: $menu-text-font-family !important;
  font-size: $menu-text-font-size-small !important;
  color: inherit !important;

  @include media-breakpoint-up(md) {
	font-size: $menu-text-font-size !important;
  }
}
.menu-proposta {
  border: 1px solid $light-gray !important;
  color: $gray-600 !important;
  background-color: $white !important;
}
.menu-proposta-selected {
  color: $brand-primary-1 !important;
  background-color: #ecd3d8 !important
}

.participantes-label {
  font-family: $base-font-family;
  font-size: 14px;
}

.participantes-label-name {
  font-weight: 500;
  color: $dark-blue;
  text-transform: uppercase;
}

.participantes-label-title {
  font-family: $base-font-family;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  padding-left: 20px;
  padding-top: 20px;
}

.participantes-label-title-comprador {
  color: $purple;
}

.participantes-label-title-vendedor {
  color: $dark-green;
}

.participantes-label-title-IntervenienteQuitante {
  color: $gray-600;
}

.participantes-label-title-imovel {
  color: $brand-primary-1;
}

.participantes-label-medium {
  font-weight: 500;
}

.card-proposta-title {
  list-style: none;
  margin: 0px;
  padding: 0;
  margin-bottom: 24px;
  height: 6px !important;
  width: 100%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16)
}

.icon_integra_doc_success {
  font-size: 20px !important;
  color: $dark-green !important;
}
.icon_integra_doc_error {
  font-size: 20px !important;
  color: $dark-red !important;
}
.icon_integra_doc {
  font-size: 20px !important;
  color: $dark-blue !important;
}
.icon_integra_doc_rec{
  font-size: 20px !important;
  color: $black !important;
}
.card-proposta-title-comprador {
  background-color: $card-proposta-title;
}

.card-proposta-title-vendedor {
  background-color: $dark-green;
}
.card-proposta-title-IntervenienteQuitante {
  background-color: $gray-600;
}
.card-proposta-title-imovel {
  background-color: $brand-primary-1;
}

.Toastify__toast--success {
  background-color: $dark-green !important;
}
.Toastify__toast--info {
  background-color: $mild-blue !important;
}
.Toastify__toast--error {
  background-color: $dark-red !important;
}
.Toastify__toast--warning {
  background-color: $dark-yellow !important;
}
.Toastify__toast-body {
  font-family: $toast-text-font-family;
  font-size: $toast-text-font-size;
  font-weight: $toast-text-font-weight;
}

.banner-trocar-senha {
  background-color: $coral;
  color: $white;
  font-size: $small-text-font-size;
  margin-left: -1.25*$spacer;
  margin-right: -1.25*$spacer;
  padding: $spacer 30px;
  text-align: center;
}

.documento-indexado {
  display: flex;
  flex: 1;
  //background-color: $light-yellow;
  border-radius: 5px;
  //border: 1px solid $dark-yellow;
  justify-content:center;
  align-items:center;
  cursor: pointer;
}
.documento-acoes {
  display: flex;
  flex: 1;
  //background-color: $light-yellow;
  border-radius: 5px;
  //border: 1px solid $dark-yellow;
  justify-content:center;
  align-items:center;
  cursor: pointer;
}
.documento-acoes-aprovado {
  display: flex;
  flex: 1;
  background-color: $mild-green;
  border-radius: 5px;
  //border: 1px solid $dark-yellow;
  justify-content:center;
  align-items:center;
  cursor: pointer;
}
.documento-acoes-substituir {
  display: flex;
  flex: 1;
  background-color: $dark-red;
  border-radius: 5px;
  //border: 1px solid $dark-yellow;
  justify-content:center;
  align-items:center;
  cursor: pointer;
}
.documento-acoes-dispensado {
  display: flex;
  flex: 1;
  background-color:  $almost-black;
  border-radius: 5px;
  //border: 1px solid $dark-yellow;
  justify-content:center;
  align-items:center;
  cursor: pointer;
}
.texto-upload {
  font-weight: 600 !important;
  font-size: $lg-default-font-multiplier * $data-font-size;
  @include media-breakpoint-up(xl) {
	font-size: $data-font-size;
  }
}
.texto-indexado {
  color: $gray-600;
  font-weight: 600;
}
.texto-aprovar {
  color: $mild-green;
  font-weight: 600;
}
.texto-substituir {
  color: $dark-red;
  font-weight: 600;
}
.texto-dispensar {
  color: $almost-black !important;
  font-weight: 600 !important;
}
.texto-substituir-icon {
  color: $dark-red;
  font-size: $icon-small-font-size !important;
}
.texto-dispensar-icon {
  color: $almost-black;
  font-size: $icon-small-font-size !important;
}
.texto-habilitado {
  color: $white;
  font-weight: 600;
}
.icon-indexado {
  font-size: $icon-small-font-size !important;
}
.small-badge {
  font-size: 10px !important;
  padding: 0 4px !important;
  height: 16px !important;
  min-width: 16px !important;
}
.file-container {
  border: 1px solid black;
  border-radius: 5px;
  padding: $spacer;
}
.file-icon {
  font-size: 3em;
  margin-right: 1.25 * $spacer;
}
.file-title {
  font-size: 12px;
  font-weight: 600;
  color: $brand-secondary-1;
}
.file-value {
  font-size: 10px;
}
.text-confirm-delete {
  font-size: 14px;
}
.header-pdf {
  background-color: $transparent-almost-black;
  height: 40px;
  box-shadow: $basic-shadow;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: $white;
  font-size: 14px;
  font-weight: 600;
}
.nav-pdf {
  background-color: $transparent-almost-black;
  height: 40px;
}
.pdf-page {
  background-color: $light-gray;
  overflow: scroll;
  height: calc(100vh - #{$header-height} - 80px - 5*#{$spacer})
}
.pdf-buttons {
  color: $white;
}

.info-documento {
  color: #3a3f53 !important;
  font-size: 20px !important;
}
.orientacao {
  font-size: 12px;
  white-space: pre-line;
}
.base-filter {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid $gray-200;
  cursor: pointer;
}
.base-filter-text {
  text-align: center;
  font-size: $lg-default-font-multiplier * $data-font-size;
  @include media-breakpoint-up(xl) {
	font-size: $data-font-size;
  }
}
.filter-selected {
  border: 1px solid $brand-secondary-1;
}
.filter-text-selected {
  color: $brand-secondary-1 !important;
  font-weight: 600 !important;
}

.botao-adicionar {
  display: flex;
  padding: 8px 18px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid $almost-black;
  cursor: pointer;
}
.botao-adicionar-text {
  color: $almost-black;
  text-align: center;
  font-size: $data-font-size;
}
.botao-adicionar-icon {
  color: $gray-600;
  font-size: 16px !important;
}
.change-analyst-icon {
  color: $brand-primary-1;
  cursor: pointer;
  font-size: 20px !important;
}
.alerta-substituido {
  color: $brand-primary-1;
}

.sla-tag-label{
  color: $white;
  margin-left: 2px;
  margin-right: 2px;
}

.sla-tag{
  border-radius: 10px;
}

.sla-red{
  background-color: $red;
  border-color: $red;
}
.sla-orange{
  background-color: $orange;
  border-color: $orange;
}
.sla-green{
  background-color: $green;
  border-color: $green;
}
.label-switch-clausula {
   margin-top: 35px !important;
}

.responsividade-botao-documento {
  margin-top: 10px;
  display: none;
  @media (max-width: 767px) {
      margin-top: 10px;
      display: block;
  }
}
@media(max-width: 425px){
  .MuiMenuItem-root{
    white-space:  normal!important;
  }
}

.w-92 {
  width: 92px;
}
